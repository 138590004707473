<template>
  <v-container>
    <v-row class="justify-center">
      <v-card width="400px" class="mt-12">
        <v-img src="@/assets/login_header.jpg" height="200px" class="white--text align-end">
          <v-card-title primary-title class="pl-5">Please register before login</v-card-title>
        </v-img>
        <v-card-text>
          <v-form @submit.prevent="submit" v-model="valid" ref="form"> 
            <!-- username -->
            <v-text-field
              name="username"
              label="Username"
              id="username"
              counter
              v-model="account.username"
              :rules="usernameRules"
            ></v-text-field>
            <!-- password -->
             <v-text-field
              name="password"
              label="password"
              id="password"
              :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
              @click:append="isShowPassword = !isShowPassword"
              :type="isShowPassword ? 'text':'password'"
              counter
              v-model="account.password"
              :rules="passwordRules"
            ></v-text-field>

            <!-- button -->
            <v-row class="justify-space-between px-3 pt-5">
              <v-btn text @click.prevent="$router.push('/login')">go to Login</v-btn>
              <v-btn type="submit" class="default">Register</v-btn>
            </v-row>

            <v-row class="px-3 pt-5 justify-center">
              <span class="red--text">{{registerMessage}}</span>
            </v-row>

          </v-form>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>

import api from "@/services/api";

export default {
    name: "Register",
    data() {
      return {
        valid: true,
        isShowPassword: false,
        registerMessage: "",
        account: {
          username: '',
          password: ''
        },
        usernameRules:[v => !!v || "Username is required", v => v.length > 4 || 'Username must be more than or equal 5 chars'],
        passwordRules:[v => !!v || "Password is required", 
          v => /^[A-Za-z\d]{8,}$/.test(v) || "Password must be more than or equal 8 chars"
        ], //หมายความว่า ถ้า password ความยาวมากกว่า 7 ตัวอักษร ไม่ต้องแสดงไรออกมา แต่ถ้าน้อยกว่า ให้แสดงข้อความ // 
        //https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a

      }
    },
    methods: {
      async submit() {
         if (this.$refs.form.validate()) {
           let result = await api.register(this.account);
           if (result.result == "nok") {
              this.registerMessage = result.message;
           } else {
             this.registerMessage = "สมัครสมาชิกสำเร็จ กรุณารอสักครู่...."
             this.$router.push("/login");
           }
         }
      }
    },
}
</script>

<style>

</style>